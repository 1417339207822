import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { tr } from "date-fns/locale";

export const formatDate = (date: Date | string): string => {
  return format(date, "d MMMM yyyy", { locale: tr });
};

export const formatDateShort = (date: Date | string): string => {
  return format(date, "dd.MM.yy", { locale: tr });
};

export const formatDateNoYear = (date: Date | string): string => {
  const dateInUTC = toZonedTime(date, "UTC");
  return format(dateInUTC, "d MMMM", { locale: tr });
};

export const formatTime = (date: Date | string): string => {
  return format(date, "HH:mm", { locale: tr });
};

export const formatDateAndTime = (date: Date | string): string => {
  return format(date, "d MMMM yyyy - HH:mm", { locale: tr });
};

export const formatDateWithDayName = (date: Date | string): string => {
  return format(date, "d MMMM yyyy, EEEE", { locale: tr });
};

export const formatDateAndTimeNoYear = (date: Date | string): string => {
  return format(date, "d MMMM / HH:mm", { locale: tr });
};
